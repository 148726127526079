// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Particle container styles */
.particle-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -1;
  overflow: hidden;
}

/* Custom styles for particles */
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Animated background gradients */
#particles-js::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(45deg, #00f2fe, #4facfe); */
  background-image: linear-gradient(
    to bottom,
    #fefefe,
    #faf6fb,
    #faeef3,
    #fce5e8,
    #faded8,
    #f6d9cf,
    #f1d4c7,
    #ebd0be,
    #e9cabc,
    #e5c4bb,
    #e1beba,
    #dcb9b9
  );
  opacity: 0.9;
  z-index: -1;
}

/* Keyframes for gradient animation */
@keyframes gradientAnim {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.6;
  }
}

/* Particle animation */
.particle {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  pointer-events: none; /* Ignore mouse events */
}

/* Particle hover animation */
.particle:hover {
  transform: scale(1.5);
  background-color: rgba(255, 255, 255, 1);
  transition: transform 0.3s, background-color 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/ParticleComponent.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA,gCAAgC;AAChC;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA,kCAAkC;AAClC;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,0DAA0D;EAC1D;;;;;;;;;;;;;;GAcC;EACD,YAAY;EACZ,WAAW;AACb;;AAEA,qCAAqC;AACrC;EACE;;IAEE,YAAY;EACd;EACA;IACE,YAAY;EACd;AACF;;AAEA,uBAAuB;AACvB;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,0CAA0C;EAC1C,kBAAkB;EAClB,oBAAoB,EAAE,wBAAwB;AAChD;;AAEA,6BAA6B;AAC7B;EACE,qBAAqB;EACrB,wCAAwC;EACxC,iDAAiD;AACnD","sourcesContent":["/* Particle container styles */\n.particle-container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: #000;\n  z-index: -1;\n  overflow: hidden;\n}\n\n/* Custom styles for particles */\n#particles-js {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n\n/* Animated background gradients */\n#particles-js::before {\n  content: \"\";\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  /* background: linear-gradient(45deg, #00f2fe, #4facfe); */\n  background-image: linear-gradient(\n    to bottom,\n    #fefefe,\n    #faf6fb,\n    #faeef3,\n    #fce5e8,\n    #faded8,\n    #f6d9cf,\n    #f1d4c7,\n    #ebd0be,\n    #e9cabc,\n    #e5c4bb,\n    #e1beba,\n    #dcb9b9\n  );\n  opacity: 0.9;\n  z-index: -1;\n}\n\n/* Keyframes for gradient animation */\n@keyframes gradientAnim {\n  0%,\n  100% {\n    opacity: 0.8;\n  }\n  50% {\n    opacity: 0.6;\n  }\n}\n\n/* Particle animation */\n.particle {\n  position: absolute;\n  width: 8px;\n  height: 8px;\n  background-color: rgba(255, 255, 255, 0.8);\n  border-radius: 50%;\n  pointer-events: none; /* Ignore mouse events */\n}\n\n/* Particle hover animation */\n.particle:hover {\n  transform: scale(1.5);\n  background-color: rgba(255, 255, 255, 1);\n  transition: transform 0.3s, background-color 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
