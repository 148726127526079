import React, { useEffect } from "react";
import "particles.js/particles";
import "./ParticleComponent.css";
import CenteredContent from "./CenteredContent";
import "./CenteredContent.css";

const ParticleComponent = () => {
  const particlesJS = window.particlesJS;

  useEffect(() => {
    particlesJS("particles-js", {
      particles: {
        number: { value: 80, density: { enable: true, value_area: 800 } },
        color: { value: "#AF5F0F" },
        shape: { type: "circle" },
        opacity: { value: 1, random: true },
        size: { value: 4, random: true },
        line_linked: {
          enable: true,
          distance: 75,
          // color: "#0bffd0",
          color: "#ffffff",
          opacity: 0.6,
          width: 1,
        },
        move: {
          enable: true,
          speed: 3,
          direction: "none",
          random: true,
          straight: false,
          out_mode: "bounce",
          bounce: true,
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: { enable: true, mode: "repulse" },
          onclick: { enable: true, mode: ["push", "repulse"] },
        },
        modes: {
          repulse: { distance: 100, duration: 0.4 },
          push: { particles_nb: 4 },
        },
      },
      retina_detect: true,
    });
  }, []);

  return (
    <>
      <div className="parent">
        <CenteredContent className="overlay-div"></CenteredContent>
      </div>
      <div id="particles-js" className="particle-container"></div>
    </>
  );
};

export default ParticleComponent;
