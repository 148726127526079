import React, { useState } from "react";
import "./CenteredContent.css";

const CenteredContent = ({ children }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = (e) => {
    const computedStyle = window.getComputedStyle(e.target);
    const opacity = computedStyle.getPropertyValue("opacity");
    if (parseFloat(opacity) > 0) {
      setIsHovered(true);
    }
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="centered-content">
      <h1>
        <span className="welcome">Welcome to Sayan's digital home.</span>
      </h1>
      <div className="icons stay-tuned">
        <div
          className="icon-container show-me"
          onMouseEnter={handleHover}
          onMouseLeave={handleLeave}
        >
          <img
            className="icon scalable"
            src="/me.png"
            alt="About me"
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
          />
          <div className={`icon-line ${isHovered ? "show-line" : ""}`}></div>
          <div className={`icon-details ${isHovered ? "icon-show" : ""}`}>
            Full time NodeJs, part time React. <br />
            Here,{" "}
            <a
              href="https://docs.google.com/document/d/1oU64olMQxURXIqqslrkRR9mvJekQSS6ggU6keG4GxRA/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="base-link"
            >
              click me
            </a>{" "}
            to grab a CV of mine!
          </div>
        </div>
        <div className="icon-container">
          <a
            href="https://www.github.com/sayanchakrabort1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon scalable" src="/github.png" alt="Github" />
          </a>
        </div>
        <div className="icon-container">
          <a
            href="https://www.linkedin.com/in/sayan-chakraborty-13b5b1122/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="icon scalable"
              src="/linked-in.png"
              alt="Linkedin"
            />
          </a>
        </div>
        <div className="icon-container">
          <a href="mailto:sayanchakraborty1996@gmail.com">
            <img className="icon scalable" src="/gmail.png" alt="Gmail" />
          </a>
        </div>
        <div className="icon-container">
          <a
            href="https://wa.me/+919830602056"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="icon scalable" src="/whatsapp.png" alt="Whatsapp" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CenteredContent;
